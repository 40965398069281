import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaComment, FaSignOutAlt, FaUserCircle, FaPlusSquare, FaPlusCircle, FaTable } from 'react-icons/fa';
import { GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi';
import { useAuth } from '../../../hooks/useAuth';
import logoFull from '../../../assets/logo/logo_header.svg';
import logo from '../../../assets/logo/logo.svg';

export default function Nav() {
  const { activeMenu, handleLogout, user, toggleMenu } = useAuth();
  const location = useLocation();
  const [userRole, setUserRole] = useState<string>('')

  useEffect(() => {
    if (user?.role) {
      setUserRole(user.role);
    }
  }, [user]);


  if (userRole === "PARTNER_FGTSPAY_MANAGER" || userRole === "PARTNER_FGTSPAY_CONSULTANT") {

    return (
      <div className={`navigation ${activeMenu ? 'active' : ''}`}>
        <ul>
          <li>
            <a href="#" className="linkLogo">
              <img
                src={logoFull}
                alt="logo"
                title="Logo"
                className={`logo-default ${activeMenu ? 'active' : ''}`}
              />
              <img
                src={logo}
                alt="logo"
                title="Logo"
                className={`logo-menu-toggle ${activeMenu ? 'active' : ''}`}
              />
            </a>
            <div className="divisionT"></div>
          </li>
          <li className={location.pathname === '/admin/home' && 'active'}>
            <Link to="/admin/home" onClick={toggleMenu}>
              <span className="icon">
                <FaHome className="fa" />
              </span>
              <span className="title">Home</span>
            </Link>
          </li>
          <>
            <li
              className={location.pathname === '/admin/fgts/create' && 'active'}
            >
              <Link to="/admin/fgts/create-pay" onClick={toggleMenu}>
                <span className="icon">
                  <FaHome className="fa" />
                </span>
                <span className="title">Criar proposta-pay</span>
              </Link>
            </li>
            <li
              className={location.pathname === '/admin/fgts/index' && 'active'}
            >
              <Link to="/admin/fgts/index" onClick={toggleMenu}>
                <span className="icon">
                  <FaHome className="fa" />
                </span>
                <span className="title">Propostas realizadas</span>
              </Link>
            </li>
          </>
          <li
            className={location.pathname === '/admin/service-channel' && 'active'}
          >
            <Link to="/admin/service-channel" onClick={toggleMenu}>
              <span className="icon">
                <FaComment className="fa" />
              </span>
              <span className="title">Canais de atendimento</span>
            </Link>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <span className="icon">
                <FaSignOutAlt className="fa" />
              </span>
              <span className="title">Sair</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }

  if (userRole === "PARTNER_MANAGER" || userRole === "PARTNER_CONSULTANT") {

    return (
      <div className={`navigation ${activeMenu ? 'active' : ''}`}>
        <ul>
          <li>
            <a href="#" className="linkLogo">
              <img
                src={logoFull}
                alt="logo"
                title="Logo"
                className={`logo-default ${activeMenu ? 'active' : ''}`}
              />
              <img
                src={logo}
                alt="logo"
                title="Logo"
                className={`logo-menu-toggle ${activeMenu ? 'active' : ''}`}
              />
            </a>
            <div className="divisionT"></div>
          </li>
          <li className={location.pathname === '/admin/home' && 'active'}>
            <Link to="/admin/home" onClick={toggleMenu}>
              <span className="icon">
                <FaHome className="fa" />
              </span>
              <span className="title">Home</span>
            </Link>
          </li>
          <>
            <li
              className={location.pathname === '/admin/fgts/create' && 'active'}
            >
              <Link to="/admin/fgts/create" onClick={toggleMenu}>
                <span className="icon">
                  <FaPlusSquare className="fa" />
                </span>
                <span className="title">Criar proposta</span>
              </Link>
            </li>
            <li
              className={location.pathname === '/admin/fgts/index' && 'active'}
            >
              <Link to="/admin/fgts/index" onClick={toggleMenu}>
                <span className="icon">
                  <FaHome className="fa" />
                </span>
                <span className="title">Propostas realizadas</span>
              </Link>
            </li>
          </>
          <li
            className={location.pathname === '/admin/service-channel' && 'active'}
          >
            <Link to="/admin/service-channel" onClick={toggleMenu}>
              <span className="icon">
                <FaComment className="fa" />
              </span>
              <span className="title">Canais de atendimento</span>
            </Link>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <span className="icon">
                <FaSignOutAlt className="fa" />
              </span>
              <span className="title">Sair</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }

  if (userRole === "USER") {
    return (
      <div className={`navigation ${activeMenu ? 'active' : ''}`}>
        <ul>
          <li>
            <a href="#" className="linkLogo">
              <img
                src={logoFull}
                alt="logo"
                title="Logo"
                className={`logo-default ${activeMenu ? 'active' : ''}`}
              />
              <img
                src={logo}
                alt="logo"
                title="Logo"
                className={`logo-menu-toggle ${activeMenu ? 'active' : ''}`}
              />
            </a>
            <div className="divisionT"></div>
          </li>
          <li className={location.pathname === '/admin/home' && 'active'}>
            <Link to="/admin/home" onClick={toggleMenu}>
              <span className="icon">
                <FaHome className="fa" />
              </span>
              <span className="title">Home</span>
            </Link>
          </li>
          <li className={location.pathname === '/admin/my-loans' && 'active'}>
            <Link to="/admin/my-loans" onClick={toggleMenu}>
              <span className="icon">
                <GiTakeMyMoney className="fa" />
              </span>
              <span className="title">Antecipações</span>
            </Link>
          </li>
          <li
            className={location.pathname === '/admin/service-channel' && 'active'}
          >
            <Link to="/admin/service-channel" onClick={toggleMenu}>
              <span className="icon">
                <FaComment className="fa" />
              </span>
              <span className="title">Canais de atendimento</span>
            </Link>
          </li>
          <li className={location.pathname === '/admin/my-data' && 'active'}>
            <Link to="/admin/my-data" onClick={toggleMenu}>
              <span className="icon">
                <FaUserCircle className="fa" />
              </span>
              <span className="title">Meus dados</span>
            </Link>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <span className="icon">
                <FaSignOutAlt className="fa" />
              </span>
              <span className="title">Sair</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className={`navigation ${activeMenu ? 'active' : ''}`}>
      <ul>
        <li>
          <a href="#" className="linkLogo">
            <img
              src={logoFull}
              alt="logo"
              title="Logo"
              className={`logo-default ${activeMenu ? 'active' : ''}`}
            />
            <img
              src={logo}
              alt="logo"
              title="Logo"
              className={`logo-menu-toggle ${activeMenu ? 'active' : ''}`}
            />
          </a>
          <div className="divisionT"></div>
        </li>
        <li className={location.pathname === '/admin/home' && 'active'}>
          <Link to="/admin/home" onClick={toggleMenu}>
            <span className="icon">
              <FaHome className="fa" />
            </span>
            <span className="title">Home</span>
          </Link>
        </li>
        {[
          'ADMIN',
          'CONSULTANT',
          'PARTNER_MANAGER',
          'PARTNER_CONSULTANT',
        ].indexOf(user.role as string) > -1 && (
            <>
              <li
                className={location.pathname === '/admin/fgts/create' && 'active'}
              >
                <Link to="/admin/fgts/create" onClick={toggleMenu}>
                  <span className="icon">
                    <FaPlusSquare className="fa" />
                  </span>
                  <span className="title">Criar proposta</span>
                </Link>
              </li>
              <li
                className={location.pathname === '/admin/fgts/create-pay' && 'active'}
              >
                <Link to="/admin/fgts/create-pay" onClick={toggleMenu}>
                  <span className="icon">
                    <FaPlusCircle className="fa" />
                  </span>
                  <span className="title">Criar proposta-pay</span>
                </Link>
              </li>
              <li
                className={location.pathname === '/admin/fgts/index' && 'active'}
              >
                <Link to="/admin/fgts/index" onClick={toggleMenu}>
                  <span className="icon">
                    <FaTable className="fa" />
                  </span>
                  <span className="title">Propostas realizadas</span>
                </Link>
              </li>
            </>
          )}
        <li className={location.pathname === '/admin/my-loans' && 'active'}>
          <Link to="/admin/my-loans" onClick={toggleMenu}>
            <span className="icon">
              <GiTakeMyMoney className="fa" />
            </span>
            <span className="title">Antecipações</span>
          </Link>
        </li>
        <li
          className={
            location.pathname === '/admin/saque-aniversario-fgts' && 'active'
          }
        >
          <Link to="/admin/saque-aniversario-fgts" onClick={toggleMenu}>
            <span className="icon">
              <GiReceiveMoney className="fa" />
            </span>
            <span className="title">Saque-Aniversário FGTS</span>
          </Link>
        </li>
        <li
          className={location.pathname === '/admin/service-channel' && 'active'}
        >
          <Link to="/admin/service-channel" onClick={toggleMenu}>
            <span className="icon">
              <FaComment className="fa" />
            </span>
            <span className="title">Canais de atendimento</span>
          </Link>
        </li>
        <li
          className={location.pathname === '/admin/create-partner' && 'active'}
        >
          <Link to="/admin/create-partner" onClick={toggleMenu}>
            <span className="icon">
              <FaUserCircle className="fa" />
            </span>
            <span className="title">Cadastrar parceiro</span>
          </Link>
        </li>
        <li className={location.pathname === '/admin/my-data' && 'active'}>
          <Link to="/admin/my-data" onClick={toggleMenu}>
            <span className="icon">
              <FaUserCircle className="fa" />
            </span>
            <span className="title">Meus dados</span>
          </Link>
        </li>
        <li>
          <a href="#" onClick={handleLogout}>
            <span className="icon">
              <FaSignOutAlt className="fa" />
            </span>
            <span className="title">Sair</span>
          </a>
        </li>
      </ul>
    </div>
  );
}
