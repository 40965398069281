import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { showModal } from '../../components/whatsapp-info-modal';
import { FaWhatsapp } from 'react-icons/fa';

import { api } from '../../services/api';

import Home from './Home';
import About from './About';
import AboutMore from './AboutMore';
import StepByStep from './StepByStep';
import SimulateNow from './SimulateNow';
import Faq from './Faq';

import './styles.scss';
import WhatsappModal from '../../components/whatsapp-info-modal';
import { useLandingPage } from '../../hooks/useLandingPage';
import InitialMessage from '../../components/initial-message-modal';

type FgtsTypes = {
  fgtsPartner?: string;
};

export type PartnerType = {
  uuid: string | null;
  name: string | null;
  friendlyName: string | null;
  logo: string | null;
  backgroundColorLogo: string | null;
  active: boolean | null;
  ownWhatsAppNumber: string | null;
};

export type ProposalParams = {
  cpf?: string;
  bankData?: {
    bankNumber?: string;
    agency?: string;
    accountNumber?: string;
    digitAccount?: string;
  };
};

const partnerDefault = {
  uuid: '',
  name: null,
  friendlyName: null,
  logo: null,
  backgroundColorLogo: null,
  active: null,
  ownWhatsAppNumber: '',
};

function Fgts({ fgtsPartner = '' }: FgtsTypes) {
  const {
    partnerParam,
    cpfParam,
    agencyParam,
    bankNumberParam,
    accountNumberParam,
  }: any = useParams();
  fgtsPartner = fgtsPartner ? fgtsPartner : partnerParam;
  const [partner, setPartner] = useState<PartnerType>(partnerDefault);
  const { handleWhatsAppButton } = useLandingPage();
  const [textWhatsApp, setTextWhatsApp] = useState(
    'Olá! Estou com dúvidas e gostaria de receber ajuda.',
  );
  const [whatsAppPhone, setWhatsAppPhone] = useState<string>('5521965773075');
  const accountNumber = accountNumberParam
    ? accountNumberParam.slice(0, -1)
    : '';
  const accountNumberDigit = accountNumberParam
    ? accountNumberParam.slice(-1)
    : '';
  const proposalParams: ProposalParams = cpfParam
    ? {
        cpf: cpfParam,
        bankData: {
          bankNumber: bankNumberParam,
          agency: agencyParam,
          accountNumber: accountNumber,
          digitAccount: accountNumberDigit,
        },
      }
    : {};

  useEffect(() => {
    if (fgtsPartner) {
      requestPartner(fgtsPartner);
    } else {
      setPartner(partnerDefault);
    }

    if (cpfParam && accountNumberParam) {
      createClientBankDataForBot();
    }
  }, []);

  useEffect(() => {
    if (partner.name !== null) {
      setTextWhatsApp(
        ` Olá! Vim pelo parceiro ${partner.friendlyName} e estou com dúvidas sobre a antecipação do FGTS.`,
      );

      if (partner.ownWhatsAppNumber) {
        setWhatsAppPhone(partner.ownWhatsAppNumber);
      }
    }
  }, [partner]);

  async function requestPartner(reqPartner: string) {
    const { data } = await api.get(
      `/partners/name/${reqPartner.toLowerCase()}`,
    );

    if (data) {
      const partnerLogoUrl = data.logo
        ? `${api.defaults.baseURL}/img/partner/${data.logo}`
        : null;

      setPartner({
        ...data,
        logo: partnerLogoUrl,
      });
      document.title = data.friendlyName;
    } else {
      setPartner(partnerDefault);
    }
  }

  const loadWhatsappPage = () => {
    handleWhatsAppButton({ phone: whatsAppPhone, text: textWhatsApp });
  };

  const createClientBankDataForBot = async () => {
    await api.post(`/proposals`, {
      value: 0,
      installment: 0,
      rate: 0,
      clientName: '',
      clientCpf: cpfParam,
      clientEmail: '',
      proposalBankData: {
        bankNumber: bankNumberParam,
        accountNumber: accountNumber,
        digitAccount: accountNumberDigit,
        agency: agencyParam,
      },
    });
  };

  if (fgtsPartner === undefined) {
    return (
      <>
        <Home
          partnerInfo={partner}
          partnerPage={!!fgtsPartner}
          proposalParams={proposalParams}
        />
        <InitialMessage partnerInfo={partner} />
        <WhatsappModal partnerInfo={partner} />
        <About partnerPage={!!fgtsPartner} />
        <AboutMore partnerPage={!!fgtsPartner} />
        <StepByStep partnerPage={!!fgtsPartner} />
        <SimulateNow partnerPage={!!fgtsPartner} />
        <Faq partnerInfo={partner} partnerPage={!!fgtsPartner} />
        <Link to="#" onClick={() => showModal()} className="buttonWhatsApp">
          <FaWhatsapp className="icon" />
        </Link>
      </>
    );
  }

  if (fgtsPartner === 'dbem') {
    return (
      <>
        <Home
          partnerInfo={partner}
          partnerPage={!!fgtsPartner}
          proposalParams={proposalParams}
        />
        <InitialMessage partnerInfo={partner} />
        <WhatsappModal partnerInfo={partner} />
        <About partnerPage={!!fgtsPartner} />
        <AboutMore partnerPage={!!fgtsPartner} />
        <StepByStep partnerPage={!!fgtsPartner} />
        <SimulateNow partnerPage={!!fgtsPartner} />
        <Faq partnerPage={!!fgtsPartner} />
      </>
    );
  }

  if (fgtsPartner !== 'dbem' || fgtsPartner !== undefined) {
    return (
      <>
        <Home
          partnerInfo={partner}
          partnerPage={!!fgtsPartner}
          proposalParams={proposalParams}
        />
        <InitialMessage partnerInfo={partner} />
        <WhatsappModal partnerInfo={partner} />
        <About partnerPage={!!fgtsPartner} />
        <AboutMore partnerPage={!!fgtsPartner} />
        <StepByStep partnerPage={!!fgtsPartner} />
        <SimulateNow partnerPage={!!fgtsPartner} />
        <Faq partnerInfo={partner} partnerPage={!!fgtsPartner} />
        <Link to="#" onClick={loadWhatsappPage} className="buttonWhatsApp">
          <FaWhatsapp className="icon" />
        </Link>
      </>
    );
  }

  return <></>;
}

export default Fgts;
