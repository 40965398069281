import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';

function InitialMessage({ partnerInfo }) {
  const { partnerParam: partnerParams }: any = useParams();
  const [partnerPhone, setPartnerPhone] = useState<string>('');
  const [partnerFriendlyname, setPartnerFriendlyName] = useState<string>('');

  useEffect(() => {
    if (partnerInfo) {

      if (partnerInfo.ownWhatsAppNumber) {
        if (partnerInfo.ownWhatsAppNumber.length === 12) {
          const secondNumbers = partnerInfo.ownWhatsAppNumber.slice(2, 4);
          const thirdNumbers = partnerInfo.ownWhatsAppNumber.slice(4, 8);
          const fourthNumbers = partnerInfo.ownWhatsAppNumber.slice(8, 12);
          const formattedNumber = `${secondNumbers} ${thirdNumbers}-${fourthNumbers}`;

          setPartnerPhone(formattedNumber);
        }

        if (partnerInfo.ownWhatsAppNumber.length === 13) {
          const secondNumbers = partnerInfo.ownWhatsAppNumber.slice(2, 4);
          const thirdNumbers = partnerInfo.ownWhatsAppNumber.slice(4, 9);
          const fourthNumbers = partnerInfo.ownWhatsAppNumber.slice(9, 13);
          const formattedNumber = `${secondNumbers} ${thirdNumbers}-${fourthNumbers}`;

          setPartnerPhone(formattedNumber);
        }
      }
      setPartnerFriendlyName(partnerInfo.friendlyName);
    }
  }, [partnerInfo]);

  const removeScroll = () => {
    document.body.classList.add('no-scroll');
  };

  useEffect(() => {

    removeScroll();

    const beforeUnloadHandler = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };

  }, []);

  const closeModal = () => {
    const modal = document.querySelector('#modal-initial-message');
    modal!.classList.add('hide');
    document.body.classList.remove('no-scroll');
  };

  if (
    partnerParams === 'credmoney'
  ) {
    document.body.classList.remove('no-scroll');

    return <></>;
  }

  if (partnerParams) {
    return (
      <>
        <div id="modal-initial-message" className="no-scroll">
          <div className="initial-message-modal-container">
            <div className="close-button-container">
              <button onClick={closeModal}>
                <p className="close-button">X</p>
              </button>
            </div>
            <h2>Fique atento e se proteja!</h2>
            <p className="initial-message-text">
              Estamos comprometidos com a sua autonomia, liberdade e segurança,
              por isso disponibilizamos uma contratação inteiramente online, com
              o uso do nosso site e canal de WhatsApp.
            </p>
            <p className="initial-message-text">
              A <strong>Tá no Bolso</strong> esclarece que não solicita nenhum
              depósito de qualquer valor.
            </p>
            <p className="initial-message-text">
              Se você tiver alguma dúvida ou desconfiar do contato feito em nome
              da <strong>Tá no Bolso</strong> e/ou{' '}
              <strong>{partnerFriendlyname ? partnerFriendlyname : ''}</strong>,
              entre em contato conosco pelos nossos canais oficiais:{' '}
              <strong>contato@tanobolso.com.br</strong>,{' '}
              <strong>0800-887-0578</strong> ou{' '}
              <strong>{partnerPhone ? partnerPhone : ''}</strong>.
            </p>
          </div>
        </div>
      </>
    );
  }

  if (!partnerParams) {
    return (
      <>
        <div id="modal-initial-message" className="no-scroll">
          <div className="initial-message-modal-container">
            <div className="close-button-container">
              <button onClick={closeModal}>
                <p className="close-button">X</p>
              </button>
            </div>
            <h2>Fique atento e se proteja!</h2>
            <p className="initial-message-text">
              Estamos comprometidos com a sua autonomia, liberdade e segurança,
              por isso disponibilizamos uma contratação inteiramente online, com
              o uso do nosso site e canal de WhatsApp.
            </p>
            <p className="initial-message-text">
              A <strong>Tá no Bolso</strong> esclarece que não solicita nenhum
              depósito de qualquer valor.
            </p>
            <p className="initial-message-text">
              Se você tiver alguma dúvida ou desconfiar do contato feito em nome
              da <strong>Tá no Bolso</strong>, entre em contato conosco pelos
              nossos canais oficiais: <strong>contato@tanobolso.com.br</strong>,{' '}
              <strong>0800-887-0578</strong> ou <strong>21 96577-3075</strong>.
            </p>
          </div>
        </div>
      </>
    );
  }

  return <></>;
}

export default InitialMessage;
