import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';

import { api } from '../../../../../services/api';

import './styles.scss';

type BalanceType = {
  proposalId: string;
  loading: boolean;
  goPageSimulationFgts: (data: any) => void;
  changeLoadingModal: (loading: boolean) => void;
  cpf: string;
  setIntegrationName: (newIntegrationName: string) => void;
};

type StatusAlertType = {
  status: string;
  message: string;
};

export default function Balance({
  proposalId,
  loading,
  goPageSimulationFgts,
  changeLoadingModal,
  cpf,
  setIntegrationName,
}: BalanceType) {
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [installments, setInstallments] = useState<any[]>([]);
  const [checkedState, setCheckedState] = useState<any[]>([]);
  const [totalValueInstallments, setTotalValueInstallments] =
    useState<number>(0);
  const [totalInstallments, setTotalInstallments] = useState<number>(7);
  const [simulateButton, setSimulateButton] = useState<boolean>(true)
  const regexMoney = /\d(?=(\d{3})+,)/g;

  useEffect(() => {
    requestBalanceFgts();
  }, []);

  function sumInstallments(installments: any) {
    return installments.reduce((sum, currentState) => {
      if (currentState.checked) {
        return sum + currentState.valor;
      }

      return sum;
    }, 0);
  }

  function clearStates() {
    setInstallments([]);
    setCheckedState([]);
    setTotalValueInstallments(0);
    setTotalInstallments(7);
  }

  async function requestBalanceFgts() {
    clearStates();

    try {
      const response = await api.post('/fgts/balance', {
        cpf,
        proposalUuid: proposalId,
      });

      if (response.data.status === 'error') {
        if (response.data.message === 'Não foi possível consultar o saldo.' || response.data.message === '' || response.data.message === "Rate limit exceeded") {
          setTimeout(requestBalanceFgts, 5000)
          setStatusMessageAlert({
            status: 'error',
            message: 'Por favor aguarde, que estamos realizando a consulta.',
          });
          setSimulateButton(false)
        }
      }

      if (response.data.balance === 0) {
        setStatusMessageAlert({
          status: 'error',
          message: 'Infelizmente não existe saldo suficiente para prosseguir com a operação.',
        });
        setSimulateButton(false)
      }

      if (response.data.status === 'success') {
        setIntegrationName(response.data.integration.name)
        const installmentsData = response.data.installments.map(
          (parcel) => {
            return {
              dataVencimento: parcel.date,
              valor: parcel.value,
              checked: true,
            };
          },
        );
        const installmentsFilteredValueEmpty = installmentsData.filter(
          (parcel) => parcel.valor > 0,
        );
        const totalValue = sumInstallments(installmentsFilteredValueEmpty);

        setInstallments(installmentsFilteredValueEmpty);
        setCheckedState(installmentsFilteredValueEmpty);
        setTotalValueInstallments(totalValue);
        setTotalInstallments(installmentsFilteredValueEmpty.length);
        setStatusMessageAlert(null)
        setSimulateButton(true)
      } else if (response.data?.errors) {
        const errorDetails = response.data.errors.map((e: any) => (
          <div key={e.key}>
            {e.message}
            <br />
          </div>
        ));

        setStatusMessageAlert({
          status: 'error',
          message: errorDetails,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu, tente novamente.',
        });
      }
    } catch (error) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Ops, algo ocorreu, tente novamente.',
      });
    }

    changeLoadingModal(false);
  }

  async function handleSimulateFgts() {
    changeLoadingModal(true);

    try {
      const data = {
        numberOfInstallments: totalInstallments,
        proposalUuid: proposalId,
      };
      const response = await api.post('/fgts/simulate', data);

      if (response.data.status === 'error') {
        if (response.data.message === 'Não foi possível consultar o saldo.' || response.data.message === "Não foi possível simular." ||
          response.data.message === 'Rate limit exceeded' || response.data.message === '') {
          setTimeout(handleSimulateFgts, 3000)
          setStatusMessageAlert({
            status: 'error',
            message: 'Por favor aguarde, que estamos realizando a consulta.',
          });
        }
      }

      if (response.data.status === 'success') {
        setStatusMessageAlert(null)
        goPageSimulationFgts(response.data);
      } else if (response.data.error) {
        changeLoadingModal(false);
        setStatusMessageAlert({
          status: 'error',
          message: response.data.message,
        });
      } else {
        changeLoadingModal(false);
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu, tente novamente.',
        });
      }
    } catch (error) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Ops, algo ocorreu, tente novamente.',
      });
    }
  }

  function onChangeTotalInstallments(parcel: number) {
    setTotalInstallments(parcel);

    const newInstallments = installments.map(
      (parcelData: any, parcelNumber: number) => {
        return {
          dataVencimento: parcelData.dataVencimento,
          valor: parcelData.valor,
          checked: parcelNumber + 1 <= parcel ? true : false,
        };
      },
    );
    const newCheckedState = newInstallments.filter((parcel) => parcel.checked);
    const totalValue = sumInstallments(newInstallments);

    setInstallments(newInstallments);
    setCheckedState(newCheckedState);
    setTotalValueInstallments(totalValue);
  }

  return (
    <>
      {!loading && (
        <>
          <div className="modal-body balance">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {statusMessageAlert && (
                    <div
                      className={
                        statusMessageAlert.status === 'error'
                          ? 'alert alert-danger'
                          : 'alert alert-primary'
                      }
                      role="alert"
                    >
                      {statusMessageAlert.message}
                    </div>
                  )}
                </div>
              </div>
              <h3>
                Abaixo estão as parcelas no qual você pode adiantar, selecione a
                quantidade e clique em simular.
              </h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-light table-sm table-hover">
                      <thead>
                        <tr>
                          <th>N° da parcela</th>
                          <th>Valor disponível</th>
                          <th>Data saque</th>
                        </tr>
                      </thead>
                      <tbody>
                        {installments?.map(
                          (parcelData: any, parcelNumber: number) => (
                            <tr
                              key={parcelNumber}
                              className={!parcelData.checked ? 'unchecked' : ''}
                            >
                              <td>{parcelNumber + 1}</td>
                              <td>
                                R${' '}
                                {parcelData.valor
                                  .toFixed(2)
                                  .replace('.', ',')
                                  .replace(regexMoney, '$&.')}
                              </td>
                              <td>
                                {format(
                                  parseISO(parcelData.dataVencimento),
                                  'dd/MM/yyyy',
                                )}
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'right' }}>
                            Valor selecionado
                          </th>
                          <th colSpan={2} style={{ textAlign: 'left' }}>
                            R${' '}
                            {totalValueInstallments
                              .toFixed(2)
                              .replace('.', ',')
                              .replace(regexMoney, '$&.')}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-xs-12">
                  <label>Quantas parcelas você deseja adiantar?</label>
                  <select
                    className="form-control"
                    value={totalInstallments}
                    onChange={(e: any) =>
                      onChangeTotalInstallments(e.target.value)
                    }
                  >
                    {installments?.map(
                      (parcelData: any, parcelNumber: number) => (
                        <option key={parcelNumber + 1} value={parcelNumber + 1}>
                          {parcelNumber + 1}
                        </option>
                      ),
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {simulateButton ?
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSimulateFgts}
              >
                Simular
              </button>
            </div>
            : ''}
        </>
      )}
    </>
  );
}
