import React, { useState, FormEvent, useEffect } from 'react';
import classes from './styles.module.scss';
import { api } from '../../../services/api';
import Simulator from './Simulator';
import { PartnerType, ProposalParams } from '../index';
import { useLandingPage } from '../../../hooks/useLandingPage';
import { isValidCpf } from '../../../utils/helpers'
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import { FaWhatsapp } from 'react-icons/fa';
import bannerImg from '../../../assets/banner/fgts_g.jpg';
import bannerPartnerImg from '../../../assets/banner/fgts_partner_g.jpeg';
import ballonsImg from '../../../assets/icons/ballons.svg';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import Favicon from 'react-favicon'

export type HomeTypes = {
  partnerPage: boolean;
  partnerInfo: PartnerType;
  proposalParams?: ProposalParams;
};

type StatusAlertType = {
  status: string;
  message: string;
};

function Home({ partnerInfo, partnerPage = false, proposalParams = {} }: HomeTypes) {
  const BASE_URL = process.env.REACT_APP_API_URL
  const productName: string = 'Saque aniversário FGTS';
  const [proposalId, setProposalId] = useState<string | null>(null);
  const [value, setValue] = useState<string>('');
  const [calculatedValue, setCalculatedValue] = useState<string | number>('');
  const [cpf, setCpf] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [bankData, setBankData] = useState<ProposalParams['bankData']>({});
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [calculated, setCalculated] = useState<boolean>(false);
  const [showSimulator, setShowSimulator] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleWhatsAppButton } = useLandingPage();
  const [textWhatsApp, setTextWhatsApp] = useState(
    'Olá! Gostaria de antecipar meu FGTS com a Tá no Bolso!',
  );
  const [whatsAppPhone, setWhatsAppPhone] = useState<string>('5521965773075');

  if (partnerInfo.name === null) {

    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init('206276471090721', undefined, options);
    ReactPixel.pageView();

    ReactGA.initialize('G-5DKCMK9XFH');
    ReactGA.send('pageview');

    const tagManagerArgs = {
      gtmId: 'GTM-TFVFNHQ',
    };
    TagManager.initialize(tagManagerArgs);

  } else if (partnerInfo?.name === 'paketa') {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init('559926069292366', undefined, options);
    ReactPixel.pageView();

    ReactGA.initialize('G-CQEVYDDH88');
    ReactGA.send('pageview');

    const tagManagerArgs = {
      gtmId: 'G-YFSLQB31QE',
    };
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setName(process.env.REACT_APP_FAKER_NAME as string);
      setCpf(process.env.REACT_APP_FAKER_CPF_FGTS as string);
      setPhone(process.env.REACT_APP_FAKER_PHONE as string);
      setEmail(process.env.REACT_APP_FAKER_EMAIL as string);
      setBirthDate(process.env.REACT_APP_FAKER_BIRTH_DATE as string);
      setValue(process.env.REACT_APP_FAKER_VALUE as string);
    }

    if (proposalParams) {
      if (proposalParams?.cpf) {
        setCpf(proposalParams.cpf);
      }

      if (proposalParams?.bankData?.accountNumber) {
        setBankData({
          bankNumber: proposalParams.bankData.bankNumber || '',
          agency: proposalParams.bankData.agency || '',
          accountNumber: proposalParams.bankData.accountNumber,
          digitAccount: proposalParams.bankData.digitAccount || '',
        });
      }
    }
  }, []);

  async function handleSimulate(event: FormEvent) {
    event.preventDefault();

    setStatusMessageAlert(null);

    if (
      name === '' ||
      phone === '' ||
      email === '' ||
      cpf === '' ||
      birthDate === '' ||
      value === ''
    ) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha todos os campos obrigatórios.',
      });
      return;
    }

    if (!isValidCpf(cpf)) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Número de CPF inválido.',
      });
      setLoading(false)
      return
    }

    setLoading(true);

    const formattedPhone = phone.replace(/[^0-9]/g, '')
    const formattedCpf = cpf.replace(/[^0-9]/g, '')
    const formattedBalance = parseInt(value.replace('.', '').replace(',', '.'))

    const { data: integrationDefault } = await api.get(
      `/integration-product/product/name/${productName}/default`,
    );
    console.log(integrationDefault)

    if (!!integrationDefault === true) {
      try {
        const { data } = await api.post('/fgts/simulation-balance-informed', {
          name,
          cellPhone: formattedPhone,
          cpf: formattedCpf,
          email,
          birthDate,
          balance: formattedBalance,
          partnerUuid: partnerInfo.uuid,
        });

        if (data.status === 'success') {
          const regexMoney = /\d(?=(\d{3})+,)/g;
          const { balance, proposalUuid } = data;
          const formattedCalculatedValue = balance
            .toFixed(2)
            .replace('.', ',')
            .replace(regexMoney, '$&.');

          setProposalId(proposalUuid);
          setCalculatedValue(formattedCalculatedValue);
          setStatusMessageAlert(null);
          setCalculated(true);
        } else if (data.status === 'error') {
          setStatusMessageAlert({
            status: 'error',
            message: data.message,
          });
        } else {
          setStatusMessageAlert({
            status: 'error',
            message: 'Ops, algo ocorreu, tente novamente.',
          });
        }

      } catch (error) {
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu, tente novamente.',
        });

      }
    } else {
      sendForWhatsApp(event);
    }

    setLoading(false);
  }

  function sendForWhatsApp(event: React.FormEvent<Element>) {
    event.preventDefault();

    setStatusMessageAlert(null);

    if (
      partnerInfo?.name !== null
    ) {
      handleWhatsAppButton({ phone: whatsAppPhone, text: textWhatsApp });
      return;
    }

    if (
      name === '' ||
      phone === '' ||
      email === '' ||
      cpf === '' ||
      birthDate === '' ||
      value === ''
    ) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha todos os campos obrigatórios.',
      });
      return;
    } else {
      handleWhatsAppButton({ phone: whatsAppPhone, text: textWhatsApp });
    }
  }

  useEffect(() => {
    if (partnerInfo.name !== null) {
      setTextWhatsApp(
        ` Olá! Vim pelo parceiro ${partnerInfo.friendlyName} e gostaria de antecipar meu FGTS!`,
      );

      if (partnerInfo.ownWhatsAppNumber) {
        setWhatsAppPhone(partnerInfo.ownWhatsAppNumber);
      }
    }
  }, [partnerInfo]);

  function handleSimulateAgain(event: FormEvent) {
    event.preventDefault();

    setCalculated(false);
  }

  function handleShowSimulator() {
    setShowSimulator(true);
  }

  function handleCloseSimulator() {
    setShowSimulator(false);
  }

  return (
    <>
      {partnerInfo ? <Favicon url={`${BASE_URL}/img/favicon/${partnerInfo?.name}.ico?v=2`} /> : ''}

      <Simulator
        showModal={showSimulator}
        handleCloseSimulator={handleCloseSimulator}
        proposalId={proposalId}
        cpf={cpf}
        name={name}
        phone={phone}
        email={email}
        bankData={bankData}
        partnerPage={partnerPage}
        partnerInfo={partnerInfo}
      />
      <section
        className={classes.home}
        style={{
          backgroundImage: `url(${partnerPage ? bannerPartnerImg : bannerImg})`,
        }}
      >
        <div
          className={`container ${partnerPage ? classes['partner-fgts'] : ''}`}
        >
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-9 col-xs-12" id="simulate-card">
              <div className={classes.card}>
                <div className={classes.cardIcon}>
                  <div className={classes.roundIcon}>
                    <img
                      src={ballonsImg}
                      alt="Imagem de balão"
                      style={{ stroke: '#000', fill: 'none' }}
                    />
                  </div>
                </div>
                <div className={classes.cardBody}>
                  {partnerPage && (
                    <>
                      <h3 className={classes.cardTitle}>
                        Agora você pode antecipar até 10 saques-aniversário do
                        seu FGTS!
                      </h3>

                      <p>
                        Descubra de forma rápida e descomplicada o quanto você
                        pode liberar do seu Fundo.
                      </p>

                    </>
                  )}
                  {!partnerPage && (
                    <>
                      <h3 className={classes.cardTitle}>
                        Antecipação Saque-Aniversário!
                      </h3>
                      <div></div>
                      <p className={classes.cardText}>
                        Preencha seus dados para simular sem compromisso.
                      </p>
                      <div className={classes.cardForm}>
                        {statusMessageAlert && (
                          <div
                            className={
                              statusMessageAlert.status === 'error'
                                ? 'alert alert-danger alert-text-center'
                                : 'alert alert-primary alert-text-center'
                            }
                            role="alert"
                          >
                            {statusMessageAlert.message}
                          </div>
                        )}
                        {!calculated ? (
                          <form onSubmit={handleSimulate}>
                            <div className="row">
                              <div className="col-xs-12 col-lg-6 form-group">
                                <label htmlFor="nome">Nome completo*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nome"
                                  onChange={(e: any) => setName(e.target.value)}
                                  value={name}
                                  placeholder=""
                                />
                              </div>
                              <div className="col-xs-12 col-lg-6 form-group">
                                <label htmlFor="inputEmail">Email*</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="inputEmail"
                                  onChange={(e: any) =>
                                    setEmail(e.target.value)
                                  }
                                  value={email}
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col form-group">
                                <label htmlFor="inputCpf">CPF*</label>
                                <InputMask
                                  mask="999.999.999-99"
                                  className="form-control"
                                  id="inputCpf"
                                  onChange={(e: any) =>
                                    setCpf(
                                      e.target.value.replace(/[^0-9]/g, ''),
                                    )
                                  }
                                  value={cpf}
                                  placeholder=""
                                />
                              </div>
                              <div className="col form-group">
                                <label htmlFor="inputPhone">Celular*</label>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  className="form-control"
                                  id="inputPhone"
                                  onChange={(e: any) =>
                                    setPhone(e.target.value)
                                  }
                                  value={phone}
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col form-group">
                                <label htmlFor="inputBirthDate">
                                  Data de nasc.*
                                </label>
                                <InputMask
                                  mask="99/99/9999"
                                  className="form-control"
                                  id="inputBirthDate"
                                  onChange={(e: any) =>
                                    setBirthDate(e.target.value)
                                  }
                                  value={birthDate}
                                  placeholder=""
                                />
                              </div>
                              <div className="col form-group">
                                <label htmlFor="inputValue">
                                  Saldo aproximado do FGTS*
                                </label>
                                <CurrencyInput
                                  className="form-control"
                                  id="inputValue"
                                  onValueChange={(value) => setValue(value)}
                                  value={value}
                                  prefix={'R$ '}
                                  decimalsLimit={2}
                                  decimalSeparator={','}
                                  groupSeparator={'.'}
                                />
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-block btn-primary"
                            >
                              {loading ? 'CALCULANDO...' : 'SIMULAR AGORA'}
                            </button>
                            <button
                              type="button"
                              className="btn btn-block btn-whatsapp"
                              onClick={sendForWhatsApp}
                            >
                              <FaWhatsapp className="icon" /> Simule agora pelo
                              WhatsApp
                            </button>
                          </form>
                        ) : (
                          <div className={classes.simulatedData}>
                            <h4>Você pode receber até</h4>
                            <h3>R$ {calculatedValue}</h3>
                            <span>
                              Simular novamente?{' '}
                              <a href="/" onClick={handleSimulateAgain}>
                                Sim!
                              </a>
                            </span>
                            <button
                              type="button"
                              className="btn btn-block btn-primary"
                              onClick={handleShowSimulator}
                            >
                              CONTRATAR AGORA
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {partnerPage && (
        <section className={classes['form-partner-fgts']}>
          <div className={classes['div-inputs']}>
            {!calculated ? (
              <>
                <p>
                  <strong>
                    Preencha as informações para realizar a simulação.
                  </strong>
                </p>
                <form onSubmit={handleSimulate}>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="nome">SEU NOME COMPLETO*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nome"
                        onChange={(e: any) => setName(e.target.value)}
                        value={name}
                        placeholder=""
                      />
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor="inputCpf">SEU CPF*</label>
                      <InputMask
                        mask="999.999.999-99"
                        className="form-control"
                        id="inputCpf"
                        onChange={(e: any) =>
                          setCpf(e.target.value.replace(/[^0-9]/g, ''))
                        }
                        value={cpf}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="inputEmail">SEU EMAIL*</label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        onChange={(e: any) => setEmail(e.target.value)}
                        value={email}
                        placeholder=""
                      />
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor="inputPhone">SEU WHATSAPP*</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        className="form-control"
                        id="inputPhone"
                        onChange={(e: any) => setPhone(e.target.value)}
                        value={phone}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="inputBirthDate">SUA DATA DE NASC.*</label>
                      <InputMask
                        mask="99/99/9999"
                        className="form-control"
                        id="inputBirthDate"
                        onChange={(e: any) => setBirthDate(e.target.value)}
                        value={birthDate}
                        placeholder=""
                      />
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor="inputValue">SEU SALDO DO FGTS*</label>
                      <CurrencyInput
                        className="form-control"
                        id="inputValue"
                        onValueChange={(value) => setValue(value)}
                        value={value}
                        prefix={'R$ '}
                        decimalsLimit={2}
                        decimalSeparator={','}
                        groupSeparator={'.'}
                      />
                    </div>
                  </div>
                  {statusMessageAlert && (
                    <div
                      className={
                        statusMessageAlert.status === 'error'
                          ? 'alert alert-danger alert-text-center'
                          : 'alert alert-primary alert-text-center'
                      }
                      role="alert"
                    >
                      {statusMessageAlert.message}
                    </div>
                  )}
                  {partnerInfo?.name === 'dbem' ? '' : <button
                    type="button"
                    className="btn btn-block btn-whatsapp"
                    onClick={sendForWhatsApp}
                  >
                    <FaWhatsapp className="icon" /> Simule agora pelo WhatsApp
                  </button>}

                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    disabled={loading}
                  >
                    {loading ? 'CALCULANDO...' : 'SIMULAR AGORA'}
                  </button>
                </form>
              </>
            ) : (
              <div className={classes.simulatedData}>
                <h4>Você pode receber até</h4>
                <h3>R$ {calculatedValue}</h3>
                <span>
                  Simular novamente?{' '}
                  <a href="/" onClick={handleSimulateAgain}>
                    Sim!
                  </a>
                </span>
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={handleShowSimulator}
                >
                  CONTRATAR AGORA
                </button>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default Home;
