import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { BiLike, BiDislike } from 'react-icons/bi';

import Layout from '../../../components/Admin/Layout';
import { useAuth } from '../../../hooks/useAuth';
import { api } from '../../../services/api';
import { Proposal } from '../../../interfaces/proposal.interface';

export default function Home() {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [completedLoans, setCompletedLoans] = useState<number>(0);
  const [pendingLoans, setPendingLoans] = useState<number>(0);
  const [
    proposalsAwaitingApprovalCancellation,
    setProposalsAwaitingApprovalCancellation,
  ] = useState<Proposal[] | null>(null);
  const regexMoney = /\d(?=(\d{3})+,)/g;

  const getLoans = async () => {
    try {
      const { data } = await api.get(`/proposals/${user!.id}`);

      if (data) {
        setCompletedLoans(data.completedLoans);
        setPendingLoans(data.pendingLoans);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProposalsAwaitingApprovalCancellation = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        '/proposals/awaiting-approval-cancellation',
      );

      if (data) setProposalsAwaitingApprovalCancellation(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user!.id !== '') {
      getLoans();
      getProposalsAwaitingApprovalCancellation();
    }
  }, [user]);

  const approveCancellation = async (proposal: Proposal) => {
    try {
      const url = proposal.fgtsPay
        ? '/fgts/pay/approve-cancellation'
        : '/fgts/approve-cancellation';
      const response = await api.get(`${url}/${proposal.uuid}`);

      alert(response.data.message);
    } catch (error) {
      console.log(error);

      alert(error.response.message);
    }
  };

  const cancelCancellation = async (proposal: Proposal) => {
    try {
      const url = proposal.fgtsPay
        ? '/fgts/pay/cancel-cancellation'
        : '/fgts/cancel-cancellation';
      const response = await api.get(`${url}/${proposal.uuid}`);

      alert(response.data.message);
    } catch (error) {
      console.log(error);

      alert(error.response.message);
    }
  };

  return (
    <Layout pageBreadcrumb="Home" pageTitle="Home">
      <h2>Olá, {user?.name}</h2>
      <div className="cardBox">
        <div className="card">
          <div>
            <div className="numbers">{pendingLoans}</div>
            <div className="cardName">Antecipações Pendentes</div>
          </div>
          <div className="iconBox">
            <i className="fa fa-eye" aria-hidden="true"></i>
          </div>
        </div>
        <div className="card">
          <div>
            <div className="numbers">{completedLoans}</div>
            <div className="cardName">Antecipações Finalizados</div>
          </div>
          <div className="iconBox">
            <i className="fa fa-eye" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      {user?.role &&
      (user?.role === 'ADMIN' ||
        user?.role === 'PARTNER_MANAGER' ||
        user?.role === 'PARTNER_FGTSPAY_MANAGER') ? (
        <div style={{ padding: '35px' }}>
          <div className="row" style={{ backgroundColor: '#fff' }}>
            <div className="col-md-12">
              <h3>Propostas aguardando aprovação para cancelamento</h3>
              <br />
              <table className="table table-light table-sm table-hover">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Data - Hora</th>
                    <th>Valor</th>
                    {user?.role === 'ADMIN' && <th>Parceiro</th>}
                    <th>Motivo</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={8} style={{ textAlign: 'center' }}>
                        <strong>Carregando informações</strong>
                      </td>
                    </tr>
                  ) : !proposalsAwaitingApprovalCancellation ||
                    proposalsAwaitingApprovalCancellation?.length < 1 ? (
                    <tr>
                      <td colSpan={8} style={{ textAlign: 'center' }}>
                        <strong>Não há propostas para exibir</strong>
                      </td>
                    </tr>
                  ) : (
                    proposalsAwaitingApprovalCancellation?.map((proposal) => {
                      return (
                        <tr key={proposal.uuid}>
                          <td
                            title={`id: ${proposal.uuid} nome: ${proposal.clientName}`}
                          >
                            {proposal.clientName ? proposal.clientName : ''}
                          </td>
                          <td>
                            {proposal.clientCpf ? proposal.clientCpf : ''}
                          </td>
                          <td>
                            {proposal.createdAt
                              ? format(
                                  parseISO(proposal.createdAt),
                                  'dd/MM/yyyy - HH:mm',
                                )
                              : ''}
                          </td>
                          <td>
                            R${' '}
                            {proposal.value
                              ? parseFloat(proposal.value)
                                  .toFixed(2)
                                  .replace('.', ',')
                                  .replace(regexMoney, '$&.')
                              : ''}
                          </td>
                          {user?.role === 'ADMIN' && (
                            <td>
                              {proposal.partner
                                ? proposal.partner.friendlyName
                                : ''}
                            </td>
                          )}
                          <td>{proposal.reasonForCancellation}</td>
                          <td>
                            <BiLike
                              className="edit-icon"
                              title="Aprovar cancelamento"
                              style={{ marginRight: 5 }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Tem certeza que deseja aprovar o cancelamento?',
                                  )
                                )
                                  approveCancellation(proposal);
                              }}
                            />
                            <BiDislike
                              className="edit-icon"
                              title="Cancelar cancelamento"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Tem certeza que deseja cancelar o cancelamento?',
                                  )
                                )
                                  cancelCancellation(proposal);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
}
